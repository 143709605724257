<template>
  <div class="layout-content">
    <!-- 左侧 -->
    <div class="layout-left-center">
      <!-- <img src="../../assets/glxt/bg.png" alt="" class="bg-img" /> -->
      <div class="layout-left">
        <!-- 标题 -->
        <div class="layout-title font-weight-bolder">
          干纺织 <span class="font-weight-normal">企业版</span>
        </div>
        <!-- 导航栏-->
        <div class="layout-left-menu">
          <div class="menu-center direction-between">
            <div class="row-center" @click="tabMenuClick('home')">
              <img src="../../assets/glxt/1-active.png" alt="" v-if="type == 'home'" />
              <img src="../../assets/glxt/1.png" alt="" v-else />
              <span :class="type == 'home' ? 'text-white' : ''">首页</span>
            </div>
            <div class="row-center" @click="tabMenuClick('positionRelease', '职位管理')">
              <img
                src="../../assets/glxt/2-active.png"
                alt=""
                class="imgmarg-two height-one"
                v-if="type == 'position' || type == 'positionRelease'"
              />
              <img
                src="../../assets/glxt/2.png"
                class="imgmarg-two height-one"
                alt=""
                v-else
              />
              <span
                :class="
                  type == 'position' || type == 'positionRelease' ? 'text-white' : ''
                "
                >职位管理</span
              >
            </div>
            <div class="row-center" @click="tabMenuClick('recommend')">
              <img
                src="../../assets/glxt/3-active.png"
                alt=""
                class="imgmarg-one height-one"
                v-if="type == 'recommend'"
              />
              <img
                src="../../assets/glxt/3.png"
                class="imgmarg-one height-one"
                v-else
                alt=""
              />
              <span :class="type == 'recommend' ? 'text-white' : ''">人才推荐</span>
            </div>
            <div class="row-center" @click="tabMenuClick('interactive')">
              <img
                src="../../assets/glxt/4-active.png"
                alt=""
                class="imgmarg-two"
                v-if="type == 'interactive'"
              />
              <img src="../../assets/glxt/4.png" alt="" class="imgmarg-two" v-else />
              <span :class="type == 'interactive' ? 'text-white' : ''">在线互动</span>
            </div>
            <div class="row-center" @click="tabMenuClick('manage')">
              <img
                src="../../assets/glxt/5-active.png"
                class="imgmarg-one height-two"
                alt=""
                v-if="type == 'manage' || type == 'mangeCv'"
              />
              <img
                src="../../assets/glxt/5.png"
                class="imgmarg-one height-two"
                alt=""
                v-else
              />
              <span :class="type == 'manage' || type == 'mangeCv' ? 'text-white' : ''"
                >人才管理</span
              >
            </div>
            <div class="row-center" @click="tabMenuClick('interview')">
              <img
                src="../../assets/glxt/6-active.png"
                class="imgmarg-one"
                alt=""
                v-if="type == 'interview'"
              />
              <img src="../../assets/glxt/6.png" class="imgmarg-one" alt="" v-else />
              <span :class="type == 'interview' ? 'text-white' : ''">面试管理</span>
            </div>
          </div>
        </div>
        <!-- 招聘顾问 -->
        <div class="consultant" v-if="kfObj">
          <div>专属招聘顾问</div>
          <img :src="kfObj.headpic" class="consultant-img" alt="" />
          <div class="consultant-text">{{ kfObj.real_name }}</div>
          <div class="consultant-text">很高兴为您服务！</div>
          <div class="flex-center consultant-phone">
            <img src="../../assets/glxt/7.png" alt="" />
            <span>{{ kfObj.phone }}</span>
          </div>
          <div style="font-size: 14px">扫一扫添加我的微信</div>
          <img :src="kfObj.qrcode" class="consultant-img" alt="" />
        </div>
      </div>
    </div>
    <!-- 右侧内容 -->
    <div class="el-layout-right column one">
      <!-- 右侧头部 -->
      <div class="right-heard space-between">
        <div>{{ title }}</div>
        <div class="heard-right row-center">
          <div class="tight-text pointer" @click="tabMenuClick('notice', '通知中心')">
            通知中心
          </div>
          <div class="tight-text pointer" @click="feedbackClick">帮助反馈</div>
          <div class="tight-text pointer" @click="tabMenuClick('vip')">升级VIP</div>
          <div class="row-center pointer" @click="heardClick">
            <div class="font-weight-bolder">
               {{ userDetail.name ? userDetail.name : (userDetail.user_name ? userDetail.user_name : userDetail.phone) }}
            </div>
            <img :src="userDetail.avatar_url ? userDetail.avatar_url : avatar" alt="" />
          </div>
        </div>
      </div>
      <!-- 主题区域 -->
      <el-main style="padding: 0">
        <router-view @feedbackClick="feedbackClick"></router-view>
      </el-main>
    </div>
    <div class="position-fixed heard-posi" v-if="heardShow">
      <heard-top :backstage="backstage" @navToClick="tabMenuClick"></heard-top>
    </div>
    <!-- 意见反馈已登录的状态 -->
    <mask-model
      ref="mask"
      title="意见反馈"
      width="560px"
      @submitClick="feedback"
      :interview="false"
      padding="26px 30px 38px 30px"
    >
      <div class="mask-fk">
        <div class="row mask-fk-center">
          <div class="center-text">内容描述：</div>
          <div class="center-textarea">
            <el-input
              type="textarea"
              v-model="query.content"
              placeholder="请简要描述你的问题和意见（必填）"
            >
            </el-input>
          </div>
        </div>
        <div class="mask-fk-img row">
          <div class="">问题截图：</div>
          <div class="img-upload">
            <div class="upload-text">最多3张，支持JPG，JPEG，PNG格式（选填）</div>
            <div style="margin-top: 12px">
              <div class="logo-upload row">
                <div class="row">
                  <div
                    class="el-logo-style"
                    v-for="(item, index) in styleImgList"
                    :key="index"
                    @mouseenter="mouseenterImg(index)"
                    @mouseleave="mouseleaveImg"
                  >
                    <img :src="item" alt="" />
                    <div
                      class="list-posi-style"
                      v-if="imgIndex == index"
                      @click="deleteImgClickOne(index)"
                    >
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
                <div v-if="styleImgList.length < 3">
                  <el-upload
                    class="avatar-uploader"
                    action=""
                    accept=".jpg,.png,.jpeg"
                    :show-file-list="false"
                    :http-request="uploadMainImgTwo"
                  >
                    <i class="el-icon-plus avatar-uploader-icon">
                      <div class="el-upload__text">上传文件</div>
                    </i>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mssk-fk-code row" v-if="setObj && setObj.is_captcha==1">
          <div>验证码：</div>
          <div class="row code-img">
            <el-input placeholder="验证码" v-model="query.verify"></el-input>
            <img :src="imgUrl" alt="" class="el-img-mask pointer"  @click="getCaptcha" />
          </div>
        </div>
      </div>
    </mask-model>
    <!-- 意见反馈未登录的状态 -->
    <mask-model
      ref="notLogin"
      :titleShow="false"
      sureContent="确认"
      :btnCancel="false"
      width="480px"
    >
      <div class="login-not-center">
        <div>检测到您未登录,请先 <span class="text-blue">登录/注册</span></div>
        <div>或</div>
        <div>扫一扫，请添加您的专属顾问</div>
        <img src="../../assets/login/922028.png" alt="" />
      </div>
    </mask-model>
  </div>
</template>

<script>
import heardTop from "../../components/heardTop.vue";
import maskModel from "../../components/mask.vue";
import api from "../../api/user";
import { mapState, mapGetters } from "vuex";
import enterprisesApi from "../../api/enterprises";
import toolApi from "../../api/tool";
export default {
  name: "layout",
  computed: {
    // 2. 解构状态作为计算属性
    ...mapState(["userDetail"]),
    ...mapGetters(["hasLogin"]),
  },
  components: {
    heardTop,
    maskModel,
  },

  data() {
    return {
      // 选中的类型
      type: "home",
      title: "首页",
      // 是否显示右侧弹窗
      heardShow: false,
      //  判断heard-top中怎么显示
      backstage: true,
      dialogImageUrl: "",
      dialogVisible: false,
      limit: 3,
      avatar: require("@/assets/login/922028.png"),
      styleImgList: [],
      imgIndex: -1,
      query: {
        content: "",
        img_ids: [],
        verify:""
      },
      kfObj: null,
      imgUrl:"",
      setObj:null,
      IMobj:{
        is_flush:0
      }
    };
  },

  created() {
    this.getKf();
    this.getRoute();
    this.userSig();
    this.enterpriseDetail();
    this.getUserInfo();
    this.setObj=JSON.parse(localStorage.getItem('setObj'))
  },
  //监听路由的变化
  watch: {
    $route(to, from) {
      this.title = to.meta.title;
      this.type = to.name;
      this.heardShow = false;
      this.$refs.mask.hide();
    },
  },
  mounted() {},
  // 组件销毁时将im退出登录
  beforeDestroy() {
    if (this.hasLogin) {
      let promise = this.$tim.logout();
      promise
        .then(function (imResponse) {
          // console.log("登出成功"); // 登出成功
        })
        .catch(function (imError) {
          console.warn("logout error:", imError);
        });
    }
  },
  methods: {
          // 获取图形验证码
    getCaptcha() {
      this.imgUrl =
        "https://ganfangzhi.com/" +
        "/api/common/getCaptcha" +
        "?" +
        new Date().getTime();
    },
    // 获取专属客服
    getKf() {
      enterprisesApi.getKefu().then((res) => {
        if (res.code == 200) {
          this.kfObj=res.data
          localStorage.setItem("exclusiveObj", JSON.stringify(res.data));
        }
      });
    },
    // 获取企业详情
    enterpriseDetail() {
      enterprisesApi.releasePosition({ is_mine: 1 }).then((res) => {
        if (res.code == 200) {
          this.$store.commit("entDetail", res.data);
        }
      });
    },
    // 获取腾讯im
    userSig() {
      api.getUserSig(this.IMobj).then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_SIG", res.data);
          this.timLogin(res.data.userId, res.data.userSig);
        }
      });
    },
    // 登录腾讯im
    timLogin(userID, userSig) {
      let promise = this.$tim.login({ userID: userID.toString(), userSig });
      promise
        .then((imResponse) => {
          // 登录成功
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
          }
        })
        .catch( (imError)=> {
          this.IMobj.is_flush=1
          setTimeout(()=>{
            this.userSig()
          },500)
        });
    },
    // 帮助与反馈
    feedbackClick() {
      if (this.hasLogin) {
        this.getCaptcha()
        this.query.content = "";
        this.query.verify = "";
        this.query.img_ids = [];
        this.styleImgList = [];
        this.imgIndex = -1;
        // 已登录
        this.$refs.mask.show();
      } else {
        // 未登录
        this.$refs.notLogin.show();
      }
    },
    heardClick() {
      this.heardShow = !this.heardShow;
    },
    // 获取路由地址
    getRoute() {
      // 后期在这里加上判断是否登录
      // 页面刷新获取当前的路由
      let router = this.$route;
      this.title = router.meta.title;
      this.type = router.name;
    },
    // 获取用户信息
    getUserInfo() {
      api.userDetail().then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
    //  切换路由
    tabMenuClick(type) {
      if (type == "Home") {
        api.toggleUser({ user_type: 1 }).then((res) => {
          if (res.code == 200) {
            this.$router.push({ name: type });
          }
        });
        return;
      }
      this.$router.push({ name: type });
    },

    //鼠标移除照片
    mouseleaveImg() {
      this.imgIndex = -1;
    },
    // 鼠标移入照片
    mouseenterImg(index) {
      this.imgIndex = index;
    },
    // 移除照片
    deleteImgClickOne(index) {
      this.styleImgList.splice(index, 1);
      this.query.img_ids.splice(index, 1);
    },
    // 上传企业风采
    uploadMainImgTwo(file) {
      if(file.file.type!="image/png" &&  file.file.type!="image/jpg" && file.file.type!="image/jpeg"){
        this.$util.msg('请上传png,jpg,jpeg格式的图片','error')
        return
      }
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return;
      }
      // 创建一个formData对象实例的方式
      const data = new FormData();
      data.append("iFile", file.file);
      toolApi
        .uploadImg(data)
        .then((res) => {
          this.$util.msg(res.msg);
          this.styleImgList.push(res.data.file_path);
          this.query.img_ids.push(res.data.file_id);
        })
        .catch((err) => {});
    },
    // 意见反馈提交接口
    feedback() {
      if (this.query.content == "") {
        this.$util.msg("请输入内容", "warning");
        return;
      }
       if (this.query.verify == "" && this.setObj.is_captcha==1) {
        this.$util.msg("请输入验证码", "warning");
        return;
      }
      toolApi.feedback(this.query).then((res) => {
        if (res.code == 200) {
          this.$refs.mask.hide();
          this.$util.msg("1-2 个工作日内会反馈");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./left.less";
.layout-content {
  width: 100%;
  // min-width: 1600px;
  // overflow-y: hidden;
  // min-height: 100vh;
  background: #202329;
  display: flex;
  
  .heard-posi {
    top: 3.6875rem;
    right: 9.1875rem;
    z-index: 1000;
  }
}
.mask-fk {
  margin-top: 1.625rem;
  margin-bottom: 1.8125rem;
  color: #68687a;
  font-size: 0.875rem;
  .mask-fk-center {
    margin-bottom: 1.75rem;
    .center-textarea {
      margin-left: 1.1875rem;
      /deep/.el-textarea {
        width: 390px;
        height: 120px;
      }
      /deep/ .el-textarea__inner {
        min-height: 120px !important;
      }
    }
  }
  .img-upload {
    margin-left: 1.1875rem;
    .upload-text {
      color: #9fa3af;
      font-size: 0.8125rem;
    }
    /deep/.el-upload--picture-card {
      width: 108px;
      height: 108px;
      line-height: 106px;
    }
    /deep/.el-upload-list__item {
      width: 108px;
      height: 108px;
    }
  }
  .mssk-fk-code {
    margin-bottom: 1.8125rem;
    .code-img {
      margin-left: 2.0625rem;
      /deep/.el-input {
        width: 270px;
      }
      /deep/.el-input__inner {
        height: 37px;
        line-height: 37px;
        border-radius: 0;
      }
       .el-img-mask {
          width: 140px;
          height: 40px;
        }
    }
  }
}
.login-not-center {
  margin-top: 3.625rem;
  margin-bottom: 1.625rem;
  text-align: center;
  font-size: 0.875rem;
  color: #999;
  div:nth-child(2) {
    line-height: 24px;
  }
  img {
    width: 8.5rem;
    height: 8.5rem;
    margin-top: 1.875rem;
  }
}
.logo-upload {
  margin-top: 0.625rem;
  margin-bottom: 2.0625rem;
  .el-logo-style {
    width: 5.3125rem;
    height: 5.3125rem;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .list-posi-style {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      margin-right: 10px;
      color: #ffffff;
    }
  }
  /deep/ .el-upload {
    border: 1px dashed #e3e7ed;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 5.3125rem;
    height: 5.3125rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .el-upload__text {
    font-size: 12px;
    margin-top: 12px;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 0.75rem;
    color: #8c939d;
  }
  .avatar {
    width: 5.3125rem;
    height: 5.3125rem;
    display: block;
  }
}
</style>
